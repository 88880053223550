import { Injectable } from '@angular/core';
import { getToken, Messaging } from '@angular/fire/messaging';
import { SmartLoggerService } from '@smarthotel/angular-services';
import { ConfirmModalComponent } from '@smarthotel/angular-ui';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, from, map, switchMap, take, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';

@Injectable()
export class PushNotificationsService {
  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly accountService: AccountService,
    private readonly msg: Messaging,
    private readonly logger: SmartLoggerService,
    private readonly modalService: BsModalService,
  ) {
    if (environment.registerPushNotifications && environment.firebaseCreds) {
      this.accountService
        .$currentUser()
        .pipe(take(1))
        .subscribe(() => {
          this.#askForPushNotificationPermission();
        });
    }
  }

  init() {
    if (environment.registerPushNotifications && environment.firebaseCreds) {
      this.authService.$loggedIn
        .pipe(
          filter(e => e),
          switchMap(() => this.#initNotifications()),
        )
        .subscribe();
    }
  }

  registerToken(query: { ownerId: unknown; companyId: unknown; token: unknown }) {
    return this.httpService.post('/notifications-manager/register/push', {
      ownerId: query.ownerId,
      token: query.token,
      deviceType: 'web',
    });
  }

  #askForPushNotificationPermission() {
    if (!this.#canAskForPermissions()) {
      return;
    }
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        text: {
          header: 'SH.GET_NOTIFIED',
          body: `SH.GET_NOTIFIED.TEXT`,
          submit: 'SH.ENABLE_NOTIFICATIONS',
          cancel: 'SH.NOT_NOW',
          btnClass: 'primary',
          icon: 'bell-dot',
        },
        confirmFunc: () => {
          this.#initNotifications().subscribe();
        },
      },
      class: 'modal-dialog-small',
    });
  }

  #getFirebaseTokenAndRegister(reg: ServiceWorkerRegistration) {
    return combineLatest([
      getToken(this.msg, {
        vapidKey: environment.vapidKey,
        serviceWorkerRegistration: reg,
      }),
      this.accountService.$currentUser(),
      this.companyService.$currentUser(),
    ]).pipe(
      map(([token, account, company]) => ({
        token,
        ownerId: account._id,
        companyId: company._id,
      })),
      take(1),
    );
  }

  #canAskForPermissions(): boolean {
    return Notification.permission === 'default';
  }

  #initNotifications() {
    return from(Notification.requestPermission()).pipe(
      filter((access: string) => access === 'granted'),
      switchMap(() => from(navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js'))),
      filter(e => !!e),
      switchMap(reg => this.#getFirebaseTokenAndRegister(reg)),
      switchMap(query => this.registerToken(query)),
      catchError(err => {
        this.logger.error(err);
        return throwError(() => err);
      }),
    );
  }
}
