@if (cfg.image) {
  <img [src]="cfg.image" alt="" class="image"/>
}
@if (cfg.icon) {
  <img src="/assets/icons/{{cfg.icon}}.svg" alt="" class="icon"/>
}
@if (cfg.header || cfg.body) {
  <div class="content">
    @if (cfg.header) {
      <h3 translate>{{ cfg.header }}</h3>
    }
    @if (cfg.body) {
      <p [innerHTML]="cfg.body | translate | nlToBr"></p>
    }
  </div>
}
<sh-button-rectangle
  (click)="onCancelClick()"
  label="{{ cfg.cancel ?? '' | translate }}"
  size="l"
  type="secondary"
></sh-button-rectangle>
<sh-button-rectangle
  (click)="onConfirmClick()"
  *ngIf="cfg?.submit"
  [type]="cfg.btnClass ?? 'primary'"
  label="{{ cfg.submit | translate }}"
  size="l"
></sh-button-rectangle>
<sh-button-rectangle
  (click)="onConfirm2Click()"
  *ngIf="cfg?.submit2 && confirmFunc2nd"
  [type]="cfg.btnClass ?? 'primary'"
  label="{{ cfg.submit2 ?? '' | translate }}"
  size="l"
></sh-button-rectangle>
<small *ngIf="cfg?.hint" class="hint">{{ cfg.hint }}</small>
